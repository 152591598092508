import {
  DefaultExternalTaggingService,
  ExternalTaggingService
} from '@empathyco/x-components/tagging';
import { Result } from '@empathyco/x-types';
import { DefaultSessionService } from '@empathyco/x-utils';

/**
 * Primor implementation for the {@link ExternalTaggingService}.
 *
 * @public
 */
export class PrimorPDPAddToCartService extends DefaultExternalTaggingService {
  public static instance: ExternalTaggingService = new PrimorPDPAddToCartService();

  public constructor() {
    super();
  }

  /**
   * Stores in the local storage the information from the Result clicked by the user
   * in order to be able to track later on. The Result stored is enhanced with
   * the session for the checkout tagging event.
   *
   * @param result - The result to store.
   *
   * @public
   */
  storeResultClicked(result: Result): void {
    const key = result[this.storageKey as keyof Result] as string;
    const storageId = this.getStorageId(DefaultExternalTaggingService.RESULT_CLICKED_ID_KEY, key);
    const session = DefaultSessionService.instance.getSessionId();
    if (result.tagging?.checkout?.params) {
      result.tagging.checkout.params.session = session;
    }
    if (storageId) {
      this.localStorageService.setItem(storageId, result, this.storageTTLMs);
    }
  }

  /**
   * Checks if the local storage contains a result information for the given id and moves
   * the result info from the local storage to the session storage without deleting it from
   * the local storage.
   *
   * @param id - The id of the result to move to the session storage.
   *
   * @public
   */
  moveToSessionStorage(id?: string): void {
    const storageId = this.getStorageId(DefaultExternalTaggingService.RESULT_CLICKED_ID_KEY, id);
    if (storageId) {
      const result = this.localStorageService.getItem(storageId);
      if (result) {
        this.sessionStorageService.setItem(storageId, result);
      }
    }
  }
}
