import {
  createWireFromFunction,
  InstallXOptions,
  mapWire,
  wireService,
  XEventPayload
} from '@empathyco/x-components';
import { I18n, cssInjector } from '@empathyco/x-archetype-utils';
import { default as AppComponent } from '../App.vue';
import * as messages from '../i18n/messages';
import store from '../store';
import { adapter } from '../adapter/adapter';
import { useDevice } from '../composables/use-device.composable';
import { mergeSemanticQueriesConfigWire } from './wiring/semantic-queries.wiring';
import { PrimorPDPAddToCartService } from './primor-pdp-add-to-cart.service';

const device = useDevice();
const wirePDPAddToCartService = wireService(PrimorPDPAddToCartService.instance);
const storeClickedResultWire = wirePDPAddToCartService('storeResultClicked');

const updateResultVariantWire = createWireFromFunction<XEventPayload<'UserSelectedAResultVariant'>>(
  ({ store, eventPayload }) => {
    const resultFromEvent = { ...eventPayload.result };
    const variantSelected = eventPayload.variant;
    const queryPreviewHash = eventPayload.queryPreviewHash;

    if (resultFromEvent) {
      const updateResult = {
        ...resultFromEvent,
        price: {
          value: variantSelected.salePrice,
          originalValue: variantSelected.originalPrice,
          hasDiscount:
            variantSelected.salePrice < (variantSelected.originalPrice ?? variantSelected.salePrice)
        },
        discountPct: variantSelected.discountPct,
        variantValue: variantSelected.variantValue,
        images: [variantSelected.imageLink],
        url: variantSelected.link ?? resultFromEvent.url,
        hexadecimalCode: variantSelected.hexadecimalCode,
        variantAttributeId: variantSelected.variantAttributeId,
        variantOptionId: variantSelected.variantId
      };
      if (queryPreviewHash) {
        store.commit('x/queriesPreview/updateAQueryPreviewResult', {
          result: updateResult,
          queryPreviewHash
        });
      } else {
        store.commit('x/search/updateResult', updateResult);
      }
    }
  }
);

/**
 * Moves the result information from the local storage to session storage.
 *
 * @public
 */
const moveClickedResultToSessionWire = mapWire(
  wirePDPAddToCartService('moveToSessionStorage'),
  (payload: string) => {
    return payload === 'url' ? undefined : payload;
  }
);

/**
 * Function that returns the options to install x-components.
 *
 * Returns - the InstallXOptions.
 */
export const getInstallXOptions: InstallXOptions = {
  adapter,
  store,
  rootComponent: AppComponent,
  domElement: getDomElement,
  xModules: {
    facets: {
      config: {
        filtersStrategyForRequest: 'leaves-only'
      }
    },
    semanticQueries: {
      config: {
        threshold: 50,
        maxItemsToRequest: 10
      },
      wiring: {
        SemanticQueriesConfigProvided: {
          mergeSemanticQueriesConfigWire
        }
      }
    },
    search: {
      wiring: {
        UserSelectedAResultVariant: {
          updateResultVariantWire
        }
      }
    },
    queriesPreview: {
      wiring: {
        UserSelectedAResultVariant: {
          updateResultVariantWire
        }
      }
    },
    tagging: {
      wiring: {
        UserClickedResultAddToCart: {
          storeClickedResultWire
        },
        UserClickedAResult: {
          storeClickedResultWire
        },
        PDPIsLoaded: {
          moveClickedResultToSessionWire
        },
        ResultURLTrackingEnabled: {
          moveClickedResultToSessionWire
        }
      }
    }
  },
  async installExtraPlugins({ app, snippet }) {
    const i18n = await I18n.create({
      locale: snippet.uiLang,
      device: (snippet.device as string) ?? device.deviceName.value,
      fallbackLocale: 'en',
      messages
    });

    app.use(i18n);
    app.config.globalProperties.$setLocale = i18n.setLocale.bind(i18n);
    app.config.globalProperties.$setLocaleDevice = i18n.setDevice.bind(i18n);
  }
};

/**
 * Creates a DOM element to mount the X Components app.
 *
 * @returns The DOM element.
 */
function getDomElement(): Element {
  const container = document.createElement('div');
  container.classList.add('x-root-container');
  const domElement = document.createElement('div');

  container.appendChild(domElement);
  cssInjector.setHost(document.head);

  document.body.appendChild(container);
  return domElement;
}
