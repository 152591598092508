export const queriesPreviewFallback: Record<string, any> = {
  es: [
    {
      query: 'san valentín perfumes',
      title: 'Perfumes destacados'
    },
    {
      query: 'san valentín ella',
      title: 'Regalos para ella'
    },
    {
      query: 'san valentín él',
      title: 'Regalos para él'
    },
    {
      query: 'cosmética lujo',
      title: 'Cosmética de lujo para tu piel'
    }
  ],
  fr: [
    {
      query: 'Parfum',
      title: 'Les meilleurs parfums'
    },
    {
      query: 'Maquillage',
      title: 'Pour un look impeccable'
    },
    {
      query: 'Soin Visage',
      title: 'Révélez votre beauté!'
    },
    {
      query: 'Cheveux',
      title: 'Prenez soin de vos cheveux'
    }
  ],
  pt: [
    {
      query: 'Perfumes',
      title: 'A fragrância perfeita para cada momento'
    },
    {
      query: 'Cabelo',
      title: 'Cabelo saudável e brilho natural'
    },
    {
      query: 'Parafarmácia',
      title: 'Essenciais para o teu bem-estar'
    },
    {
      query: 'Maquilhagem',
      title: 'Realça a tua beleza'
    }
  ],
  it: [
    {
      query: 'san valentín perfumes',
      title: 'Profumi in evidenza'
    },
    {
      query: 'san valentín ella',
      title: 'Regali per lei'
    },
    {
      query: 'san valentín él',
      title: 'Regali per lui'
    },
    {
      query: 'cosmética lujo',
      title: 'Cosmetici di lusso per la pelle'
    }
  ]
};
