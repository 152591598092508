<template>
  <div class="x" data-test="x" :dir="documentDirection">
    <SnippetConfigExtraParams :excludedExtraParams="excludedExtraParams" />
    <SnippetCallbacks />
    <Tagging :storageKey="storageKey" :storageTTLMs="storageTTLMs" />
    <UrlHandler />
    <MainModal v-if="isOpen" />
  </div>
</template>

<script lang="ts">
  import {
    defineComponent,
    defineAsyncComponent,
    ref,
    inject,
    provide,
    watch,
    computed,
    getCurrentInstance
  } from 'vue';
  import {
    SnippetConfig,
    SnippetCallbacks,
    use$x,
    XEvent,
    XEventsTypes
  } from '@empathyco/x-components';
  import { SnippetConfigExtraParams } from '@empathyco/x-components/extra-params';
  import { Tagging } from '@empathyco/x-components/tagging';
  import { UrlHandler } from '@empathyco/x-components/url';
  import { Result } from '@empathyco/x-types';
  import { useDevice } from './composables/use-device.composable';
  import currencies from './i18n/currencies';
  import './tailwind/index.css';
  import { queriesPreviewFallback } from './x-components/queries-preview.options';

  export default defineComponent({
    components: {
      SnippetCallbacks,
      SnippetConfigExtraParams,
      Tagging,
      UrlHandler,
      MainModal: defineAsyncComponent(() =>
        import('./components/custom-main-modal.vue').then(m => m.default)
      )
    },
    setup() {
      const storageTTLMs = 604800000; // 1 week
      const openXEvents = ['UserOpenXProgrammatically', 'UserClickedOpenX'];
      const sendBeaconEvents = [
        'OnLoadBeacon',
        'OnClickBeacon',
        'OnWishlistBeacon',
        'OnViewBeacon',
        'OnProductLoadBeacon',
        'OnViewProductBeacon',
        'OnSponsoredLoadBeacon',
        'OnSponsoredViewBeacon'
      ];
      const excludedExtraParams = [
        'callbacks',
        'uiLang',
        'consent',
        'documentDirection',
        'currency',
        'filters',
        'isolate',
        'cart',
        'wishlist',
        'productId',
        'queriesPreview',
        'criteoPartnerId',
        'retailerVisitorId',
        'userAgent',
        'type',
        'store_id',
        'customer_group_id'
      ];

      const x = use$x();
      const { deviceName } = useDevice();
      const appInstance = getCurrentInstance();

      const isOpen = ref(false);

      const snippetConfig = <SnippetConfig>inject('snippetConfig');

      const documentDirection = computed(
        () =>
          document.documentElement.dir ||
          document.body.dir ||
          (snippetConfig.documentDirection ?? 'ltr')
      );

      const currencyFormat = computed(() => currencies[snippetConfig.currency!]);

      const queriesPreviewInfo = computed(() =>
        snippetConfig.queriesPreview && snippetConfig.queriesPreview.length > 0
          ? snippetConfig.queriesPreview
          : queriesPreviewFallback[snippetConfig.lang] ?? queriesPreviewFallback['fr']
      );

      /**
       * Manage a storage key based on the snippet configuration scope.
       * The tagging component uses this property for add2Cart from PDP.
       */
      const storageKey = computed(() => (snippetConfig.scope === 'mobile-app' ? 'sku' : 'url'));

      provide('currencyFormat', currencyFormat.value);
      provide('queriesPreviewInfo', queriesPreviewInfo);

      /**
       * Customer's own tracking service for criteo adds & sponsored products.
       *
       * @param url - The url to send the beacon to.
       */
      const sendBeaconUrl = async (url: string): Promise<void> => {
        if (url) {
          try {
            await fetch(`https://${url}`);
          } catch (error) {
            console.error(`Error sending beacon: ${url}`, error);
          }
        }
      };

      openXEvents.forEach(event =>
        x.on(event as XEvent, false).subscribe(() => (isOpen.value = true))
      );

      sendBeaconEvents.forEach(event =>
        x.on(event as XEvent, false).subscribe((url: string) => sendBeaconUrl(url))
      );

      x.on('UserClickedResultAddToCart', false).subscribe((result: Result) => {
        if (result.onBasketChangeBeacon) {
          sendBeaconUrl(result.onBasketChangeBeacon);
        }
      });

      x.on('SnippetCallbackExecuted', false).subscribe(
        async (payload: XEventsTypes['SnippetCallbackExecuted']) => {
          if ('UserClickedResultAddToCart' === payload.event) {
            const addedToCart = (await payload.callbackReturn) as {
              id: string;
              addedToCart: boolean;
            };
            x.emit('UserModifiedAdd2cart', { ...addedToCart });
          }
        }
      );

      watch(
        () => snippetConfig.uiLang,
        uiLang => appInstance?.appContext.config.globalProperties.$setLocale(uiLang ?? 'es')
      );

      watch(deviceName, device =>
        appInstance?.appContext.config.globalProperties.$setLocaleDevice(device)
      );

      watch(
        () => snippetConfig.cart,
        (cart: number) => {
          x.emit('CartNumberUpdated', cart);
        }
      );

      return {
        isOpen,
        storageTTLMs,
        documentDirection,
        currencyFormat,
        queriesPreviewInfo,
        excludedExtraParams,
        storageKey
      };
    }
  });
</script>

<style scoped>
  .x-modal :deep(.x-modal__content) {
    width: 100%;
    height: 100%;
    background-color: white;
    overflow: auto;
  }
</style>
<style lang="scss">
  *:not(.x-keyboard-navigation *) {
    outline: none;
  }

  .x-banner,
  .x-promoted {
    &__title {
      display: none;
    }
  }
  .x-main-modal {
    z-index: 999 !important;
  }
</style>
